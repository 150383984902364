import { i18n } from 'i18next';
import { NextPage } from 'next';
import { all, isEmpty, values } from 'ramda';
import React from 'react';
import { useSSR } from 'react-i18next';
import { IApplicationState } from 'lib/types';
import i18nConfig from './config.js';
import createIsomorphicI18next from './index';

const resolveI18nStore = (store: Record<string, any>, locale: string) => {
  try {
    /*
     * Check if store is empty by checking if all of it's value objects are empty
     * Example of empty store: (store: {key1: {}, key2: {}, ...}
     */
    const isStoreEmpty = !store[locale] || all(isEmpty, values(store[locale]));
    if (isStoreEmpty) {
      return store[locale.split('-')[0]];
    }
    return store[locale];
  } catch {
    return {};
  }
};

const withI18NextSSR =
  (namespaces: string[] | [] = []) =>
  (Page: NextPage<any>) => {
    const I18NextSSR = (props: {
      headers: any;
      locale: string;
      initialState: IApplicationState;
      initialI18nStore: Record<string, any>;
    }) => {
      const { locale, initialI18nStore } = props;

      if (initialI18nStore) {
        useSSR({ [locale]: initialI18nStore }, locale);
      }

      return <Page {...props} />;
    };

    I18NextSSR.getInitialProps = async (context: any) => {
      const { ctx } = context;
      const { serverLocale } = ctx?.req?.device || {};
      const combinedNamespaces = [...i18nConfig.defaultNamespaces, ...namespaces];

      const i18nInstance: i18n = ctx.req?.i18n || createIsomorphicI18next(ctx.req?.language).i18n;

      const locale = i18nInstance.language || serverLocale;

      try {
        // Load any unloaded language resource bundles to be ready on SSR for i18next
        const clientLoadNamespaces = (lng: string, namespaces: string[]) =>
          Promise.all(
            namespaces
              .filter((ns) => !i18nInstance.hasResourceBundle(lng, ns))
              .map((ns) => i18nInstance.reloadResources(lng, ns)),
          );

        await clientLoadNamespaces(locale, combinedNamespaces);

        const initialI18nStore = i18nInstance?.store?.data
          ? resolveI18nStore(i18nInstance.store.data, locale)
          : {};

        const componentProps = Page.getInitialProps ? await Page.getInitialProps(context) : {};

        return { initialI18nStore, locale, ...componentProps };
      } catch (error) {
        console.log(error);
        return { initialI18nStore: null, locale };
      }
    };

    return I18NextSSR;
  };

export default withI18NextSSR;
